<template>
  <div>
    <!-- <div
      class="
        container-fluid
        header-content
        mb-40
        pos-relative
        height-res-tab-small-50 height-res-100
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10 margin-auto text-center w-100"></div>
      </div>
    </div> -->
    <div style="height:100px;"></div>
    <div class="mb-100">
      <div class="">
        <span
          class="d-block text-center font-30 font-res-25 text-white font-weight-6 mb-40  "
          >REPOSITORY PROVINSI PAPUA BARAT</span
        >
          <div class="container-standar mb-10 pad-20">
            <!-- <h4 class="font-weight-7 text-primary font-20 bor-bot-1 mb-30 pb-4">Profil Desa Hanjuang</h4> -->
            <div class="text-center" v-if="loading">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
            <div class="row" v-else>
              <div class="col-lg-12">
                <div class="card shadow border-radius-10 pad-10 detail-potensi">
                  <h3 class="font-24 text-primary font-weight-7 mt-2 mb-20">
                    Detail Repository
                  </h3>
                  <b-row>
                    <b-col md="auto">
                      <b-img
                        src="https://d3e22dkm536m45.cloudfront.net/cover_book/2022/04/06/Buku%20Panduan%20Puskesmas%20Kampung%20Tatanan%20Normal%20Baru%20Pandemi%20COVID-19.jpg"
                        style="width: 250px; height: 320px;"
                        fluid
                        alt="Image"
                        class="responsive rounded-md"
                      ></b-img>
                    </b-col>
                    <b-col md="8">
                      <span class="text-primary font-20 font-weight-bold">
                        Buku Panduan Puskesmas Kampung Tatanan Normal Baru Pandemi COVID-19</span
                      >
                      <b-embed
                        v-if="format_dokumen == 'VIDEO'"
                        type="iframe"
                        aspect="16by9"
                        src=""
                        allowfullscreen
                      ></b-embed>
                      <br /><br />
                      <span class="text-dark font-14 font-weight-bold mb-20">
                        Deskripsi : Buku Panduan Puskesmas Kampung Tatanan Normal Baru Pandemi COVID-19
                      </span>
                      <br />
                      <span class="text-dark font-14 font-weight-bold">
                        Sektor : Kesehatan & Gizi
                      </span>
                      <br />
                      <span class="text-dark font-14 font-weight-bold">
                        Jenis Dokumen : Panduan / Petunjuk Teknis / Modul
                      </span>
                      <br />
                      <span class="text-dark font-14 font-weight-bold">
                        Format Dokumen : {{ format_dokumen }}
                      </span>
                      <br />
                      <span class="text-dark font-14 font-weight-bold">
                        Tanggal publish : 2022-04-06
                      </span>
                      <br />
                      <br />
                      <b-button
                        v-if="format_dokumen == 'PDF'"
                        variant="primary"
                        @click="download(link)"
                        target="_blank"
                      >
                        Download
                      </b-button>
                      <!-- <br>
                        <b-link href="https://d3e22dkm536m45.cloudfront.net/document/2022/03/18/Formulir Pendataan Keluarga SIO-PAPUA.pdf" target="_blank">Download</b-link>
                        <br>
                        <a :href="repository.document_path" target="_blank">Download</a>
                        <b-nav-item
                            class="nav-top"
                            :href="repository.document_path"
                            >
                            <b-navbar-brand
                                class="mr-0 text-primary pt-0 pb-0 font-14 font-weight-5"
                                :href="repository.document_path"
                                target="_blank"
                                >Download</b-navbar-brand
                            >
                            </b-nav-item> -->
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const repository = createNamespacedHelpers("repository");

export default {
  data() {
    return {
      format_dokumen: "PDF",
      link : "https://d3e22dkm536m45.cloudfront.net/document/2022/04/06/Buku%20Panduan%20Puskesmas%20Kampung%20Tatanan%20Normal%20Baru%20Pandemi%20COVID-19.pdf"
    };
  },
  computed: {
    ...repository.mapState(["repository", "loading"]),
  },
  created() {
    // this.getRepositoryById(this.$route.params.id);
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  },
  methods: {
    ...repository.mapActions(["getRepositoryById"]),
    download(link) {
      window.location.href = link;
    },
  },
};
</script>
